<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px;">
      <div class="query-c">
        查询：
        <Input  placeholder="请输入果树名称/编号" v-model="keyword" style="width: auto;margin-right:10px;" />
        <Input  placeholder="请输入用户名称" v-model="name" style="width: auto;margin-right:10px;" />
        <Input  placeholder="请输入用户手机号" v-model="phone" style="width: auto;margin-right:10px;" />
        <DatePicker v-model="date"
          format="yyyy-MM-dd hh:mm:ss"
          @on-change="changeDate"
          type="daterange" placement="bottom-end"
          placeholder="Select date" style="width: 200px;margin-right:10px;"  />
        <Button type="primary" size="small" @click="getPlotOrderList">查询</Button>
      </div>
      <br>
      <Table max-height="670" border stripe :columns="columns" :data="data">
        <template #total_money="{ row }">
          <div>{{ formatterPrice(row.total_money) }}</div>
        </template>
        <template #order_status="{ row }">
          <div :style="{color:colorMap[row.order_status]}">{{ orderStatusMap[row.order_status] }}</div>
        </template>
        <template #action="{ row, index }">
          <div>
            <Button class="operate-btn" v-if="['pay','shipped'].includes(row.order_status)"
              type="primary"
              size="small"
              @click="selectOrder({row,index})">{{['shipped'].includes(row.order_status)?'修改单号':'发货'}}</Button>
            <Button class="operate-btn" v-if="['pay','shipped'].includes(row.order_status)"
              type="error" size="small"
              @click="refundOrder({row,index})">退款</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="total" :page-size="pageSize"  show-elevator @on-change="changePage" />
    </div>

    <Modal v-model="expressVisible" title="提交发货信息" @on-ok="updatedShippingInfo" @on-cancel="cancel">
      <Form ref="expressInfo" :model="expressInfo" :rules="ruleInline">
        <FormItem prop="companyName">
          <Input type="text" v-model="expressInfo.companyName" placeholder="快递公司" />
        </FormItem>
        <FormItem prop="expressNumber">
          <Input type="text" v-model="expressInfo.expressNumber" placeholder="快递单号" />
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { getPlotOrderList, updatedShippingInfo, refundOrder } from '@/api'
import { formatterPrice } from '@/utils'

export default {
    name: 'orderTree',
    data() {
        return {
            columns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '订单号',
                    key: 'out_trade_no',
                },
                {
                    title: '果树名称',
                    key: 'typeName',
                },
                {
                    title: '果树编号',
                    key: 'numberName',
                },
                {
                    title: '订单状态',
                    slot: 'order_status',
                },
                {
                    title: '订单总金额（元）',
                    slot: 'total_money',
                },
                {
                    title: '收件人昵称',
                    key: 'userName',
                },
                {
                    title: '联系方式',
                    key: 'telNumber',
                },
                {
                    title: '收货地址',
                    key: 'address',
                },
                {
                    title: '用户名称',
                    key: 'name',
                },
                {
                    title: '用户手机号',
                    key: 'phone',
                },
                // {
                //     title: '快递公司',
                //     key: 'shipping_comany_name',
                // },
                // {
                //     title: '快递单号',
                //     key: 'shipping_deliver_order_no',
                // },
                // {
                //     title: '操作',
                //     slot: 'action',
                //     width: 150,
                //     align: 'center',
                // },
            ],
            data: [

            ],
            total: 0,
            activeOrder: null,
            ruleInline: {},
            expressInfo: {
                companyName: '',
                expressNumber: '',
            },
            expressVisible: false,
            page: 1,
            pageSize: 10,
            phone: '',
            keyword: '',
            name: '',
            date: [new Date(new Date().getTime() - 3600000 * 24 * 30), new Date()],
            orderStatusMap: {
                new: '新建',
                pay: '已支付',
                shipped: '已发货',
                refund: '已退款',
                completed: '已完成',
            },
            colorMap: {
                new: '#000',
                pay: '#19be6b',
                shipped: '#19be6b',
                refund: '#808695',
                completed: '#808695',
            },
        }
    },
    mounted() {
        this.getPlotOrderList()
    },
    methods: {
        formatterPrice(price) {
            return formatterPrice(price)
        },
        changeDate(date) {
            console.log(date)
            this.date = date
        },
        changePage(page) {
            console.log(page)
            this.page = page
            this.getPlotOrderList()
        },
        async getPlotOrderList() {
            const param = {
                page: this.page,
                pageSize: this.pageSize,
                startDate: this.date[0],
                endDate: this.date[1],
                name: this.name,
                phone: this.phone,
                prd_desc: this.keyword,
            }
            const { success, data: { orders, total } } = await getPlotOrderList(param)
            if (success) {
                this.total = total
                this.data = orders.map((i, idx) => {
                    i.index = (this.page - 1) * this.pageSize + idx + 1
                    try {
                        i.typeName = JSON.parse(i.prd_desc).typeName
                        i.numberName = JSON.parse(i.prd_desc).numberName
                        i.shipping_address = JSON.parse(i.shipping_address)
                    } catch (err) {
                        i.prd_desc = '-'
                        i.shipping_address = '-'
                    }
                    i = Object.assign(i, i.shipping_address)
                    return i
                })
            }
        },
        selectOrder({ row, index }) {
            console.log(index)
            console.log(row)
            this.activeOrder = row
            this.expressVisible = true
            this.expressInfo = {
                companyName: row.shipping_comany_name,
                expressNumber: row.shipping_deliver_order_no,
            }
        },
        cancel() {
            this.activeOrder = null
            this.expressVisible = false
        },
        refundOrder({ row, index }) {
            console.log(index)
            console.log(row)
            console.log(this.$Modal)
            this.activeOrder = row
            this.$Modal.confirm({
                title: '确认退款',
                content: '<p>确认退款</p>',
                onOk: async () => {
                    const param = {
                        out_trade_no: this.activeOrder.out_trade_no,
                    }
                    const { success, data } = await refundOrder(param)
                    if (success) {
                        console.log(data)
                        this.getPlotOrderList()
                    }
                    this.$Message.info('Clicked ok')
                },
                onCancel: () => {
                    this.activeOrder = null
                },
            })
        },
        async updatedShippingInfo() {
            const param = {
                out_trade_no: this.activeOrder.out_trade_no,
                shipping_comany_name: this.expressInfo.companyName,
                shipping_deliver_order_no: this.expressInfo.expressNumber,
            }
            const { success, data } = await updatedShippingInfo(param)
            if (success) {
                console.log(data)
                this.getPlotOrderList()
            }
        },
    },
}
</script>

<style scoped>

.operate-btn {
  margin-bottom: 10px;

}


.operate-btn+.operate-btn {
  margin-left: 5px;
}
</style>